import * as React from "react"
import { graphql } from "gatsby"
import "./og-template.scss"
import "./fabric_type.scss"

const FabricTypeOgTemplate = (props) => {
  const { data } = props
  const fabric_type = data.markdownRemark.frontmatter
  const { title, meta_description } = fabric_type

  return (
    <div
      className="fabric-type generated-image"
      style={{ backgroundImage: `url(/${fabric_type.thumbnail})` }}
    >
      <div className="overlay"></div>
      <div className="container">
        <div>
          <p className="sub-heading">Fabric Types</p>
          <h1>{title}</h1>
          <p className="description">{meta_description}</p>
          <p className="footer">Fabricsthan</p>
        </div>
      </div>
    </div>
  )
}

export default FabricTypeOgTemplate
export const pageQuery = graphql`
  query FabricTypeOg($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        thumbnail
        title
        meta_description
      }
    }
  }
`
